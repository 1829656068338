import { Component, HostListener, OnInit } from '@angular/core';
import * as AOS from 'aos';


@Component({
  selector: 'app-root',
  template: `
    <div class="wrapper">
      <router-outlet></router-outlet>
    </div>
    `
})


export class AppComponent implements OnInit {
  ngOnInit() {
    AOS.init();

  }

  @HostListener('scroll') scrolling() {
    console.log('scrolling');
  }


}
