import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-landing',
  templateUrl: './main-landing.component.html',
  styleUrls: ['./main-landing.component.scss']
})
export class MainLandingComponent implements OnInit {
  @Input() landingInfo;
  constructor() { }

  ngOnInit(): void { }

  goTo(){
    window.open(this.landingInfo.urlBrand, '_blank').focus();
  }
}
