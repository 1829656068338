import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BusinessComponent } from './pages/business/business.component';
import { ProductsComponent } from './pages/catalog/products/products.component';
import { ContactComponent } from './pages/contanct/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FrecuentQuestionsComponent } from './pages/frecuent-questions/frecuent-questions.component';
import { HomeComponent } from './pages/home/home.component';
import { HowItWorksComponent } from './pages/howItWorks/howItWorks.component';
import { LandingsComponent } from './pages/landings/landings.component';
import { PersonsComponent } from './pages/persons/persons.component';
import { WibondInfoComponent } from './pages/wibond-info/wibond-info.component';
import { CuotsaleShopsComponent } from './pages/catalog/shopsCopy/shops.component';
import { ShopsComponent } from './pages/catalog/shops/shops.component';

const routes: Routes = [
  { path: '', component: PersonsComponent},
  // { path: 'faq', component: FaqComponent },
  // { path: 'home', component: HomeComponent },
  // { path: 'como-funciona', component: HowItWorksComponent },
  { path: 'empresas', component: BusinessComponent },
  //  { path: 'products', component: ProductsComponent },
  { path: 'shops', component: ShopsComponent },
  { path: 'personas', component: PersonsComponent },
  { path: 'preguntas-frecuentes', component: FrecuentQuestionsComponent },
  // { path: 'contacto', component: ContactComponent },
  { path: 'que-es-wibond', component: WibondInfoComponent },
  { path: 'landings/:shopName', component: LandingsComponent },
  { path: '**', redirectTo: '', pathMatch: 'full' } // This line should always be the last one
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
