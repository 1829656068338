
import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { environment } from '../../../../environments/environment';
import { RouterLinkActive } from '@angular/router';


@Component({
    selector:    'app-aside',
    templateUrl: './aside.component.html',
    styleUrls: ['./aside.component.scss']

  })

export class AsideComponent implements OnInit {

  
    constructor(@Inject(DOCUMENT) private document: any) { }
  
    ngOnInit() {

    }
    public myFunction() {
      var x = document.getElementById("myLinks");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
    goToApp(action): void {
      let URL;
      if (action == 1) {
        URL = environment.URLlogin;
      }
      if (action == 2) {
        URL = environment.URLregistro;
      }
      this.document.location.href = URL;
    }
  

  }