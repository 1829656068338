import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit {
  @Input() paymentOptions: string;
  @Input() brandName: string;
  constructor() { }

  ngOnInit(): void {
  }

  paymentActive(payment){
    return this.paymentOptions.includes(payment);
  }

  capitalizeFirstLetter(){
    return this.brandName.charAt(0).toUpperCase() + this.brandName.slice(1);
  }

}
