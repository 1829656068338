import { Component, Host, HostListener, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { LocationService } from "src/app/services/location.service";
import { ParamsService } from "src/app/services/params.service";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent implements OnInit {
  // @Input() landingNav: boolean = false;

  public actions: any;
  public utm_source: string;
  public utm_medium: string;
  public utm_campaign: string;
  public gclid: string;

  constructor(
    private $gaService: GoogleAnalyticsService,
    private router: Router,
    private route: ActivatedRoute,
    private paramsService: ParamsService,
    private locationService: LocationService
  ) {
    this.route.queryParams.subscribe((params) => {
      this.actions = params;
      this.paramsService.setParams("utm_source", params.utm_source);
      this.paramsService.setParams("utm_medium", params.utm_medium);
      this.paramsService.setParams("gclid", params.gclid);
      this.paramsService.setParams("utm_campaign", params.utm_campaign);
    });
  }

  public country = {
    code: 10,
    value: "AR",
    src: "/assets/images/people/svg/argentina.svg",
  };
  public collapsed = true;
  public countrys = [
    {
      code: 10,
      value: "AR",
      name: "Argentina",
      src: "/assets/images/people/svg/argentina.svg",
    },
    {
      code: 11,
      value: "CL",
      name: "Chile",
      src: "/assets/images/people/svg/Chile.svg",
    },
  ];
  public countryShow;

  prevY = 0;
  isScrolling = false;
  navMobileElement: HTMLElement;
  collapsibleNavElement: HTMLElement;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(e) {
    if (!this.isScrolling) {
      this.isScrolling = true;
      requestAnimationFrame(() => {
        let newY = e.target["scrollingElement"].scrollTop;
        let diff = this.prevY - newY;

        if (diff > 0 || newY < 100) {
          this.navMobileElement.style.transform = "translateY(0)";
        } else {
          this.navMobileElement.style.transform = "translateY(-100px)";
          this.collapsibleNavElement.classList.remove("show");
        }

        this.prevY = newY;
        this.isScrolling = false;
      });
    }
  }

  // if the mouse enters the top of the screen (100px), show the navbar
  @HostListener("window:mousemove", ["$event"])
  onMouseMove(e) {
    let y = e.clientY;
    if (y < 100) {
      this.navMobileElement.style.transform = "translateY(0)";
    }
  }

  ngOnInit(): void {
    this.navMobileElement = document.getElementById("nav-mobile");
    this.collapsibleNavElement = document.getElementById("collapsibleNavbar");
    this.country = this.countrys.find((country) =>
      country.value === this.locationService.getCountry()
        ? this.locationService.getCountry()
        : undefined
    );
    if (this.country == undefined) {
      this.country = this.countrys[0];
    }
    this.countryShow = this.countrys.filter(
      (element) => element.code !== this.country.code
    );
  }

  public changeCountry(code) {
    this.country = this.countrys.find((country) => country.code === code);
    this.countryShow = this.countrys.filter(
      (element) => element.code !== this.country.code
    );
    this.locationService.setCountry(this.country.value);
  }

  public collapse() {
    this.collapsed = !this.collapsed;
  }

  clickBtn(action: string) {
    var params = "/?";
    
    if (this.actions.utm_source != undefined) {
      params = params.concat("utm_source=", this.actions.utm_source, "&");
    }
    if (this.actions.utm_medium != undefined) {
      params = params.concat("utm_medium=", this.actions.utm_medium, "&");
    }
    if (this.actions.utm_campaign != undefined) {
      params = params.concat("utm_campaign=", this.actions.utm_campaign, "&");
    }
    if (this.actions.gclid != undefined) {
      params = params.concat("gclid=", this.actions.gclid);
    }
    if (this.country.value === "CL") {
      params = params.concat("country=", "CHILE");
    } else {
      params = params.concat("country=", "ARGENTINA");
    }
    switch (action) {      
      case "signup":
        params = params.concat("&sourceCode=SitioWeb_CTACrearCuenta");
        this.$gaService.event("clic", "com_header", "crear_cuenta_header");
        window.open("https://app.wibond.co/auth/signup-v3" + params, "_blank");
        break;
      case "login":
        this.$gaService.event("clic", "com_header", "iniciar_sesion_header");
        window.open("https://app.wibond.co/auth/login" + params, "_blank");
        break;
      case "comenzar-ahora":
        this.$gaService.event(
          "comenzar-ahora",
          "comenzar-ahora",
          "Comenzar ahora"
        );
        window.open("https://app.wibond.co/auth/signup-v3" + params, "_blank");
        break;
      case "integracion":
        this.$gaService.event("apidocs", "apidocs", "API doc");
        window.open(
          "https://stoplight.io/p/docs/gh/itwibond/api-doc",
          "_blank"
        );
        break;
      case "tel":
        this.$gaService.event("telefono", "telefono", "Telefono click");
        window.open(
          "https://api.whatsapp.com/send?phone=5491160283777&text=Hola,%20necesito%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Wibond.",
          "_blank"
        );
        break;
      case "email":
        this.$gaService.event("email", "email", "email hola");
        window.open("mailto:hola@wibond.co", "_blank");
        break;
      case "faq":
        this.$gaService.event("faq", "click", "faqs");
        window.open("https://wibond.zendesk.com/hc/es", "_blank");
        break;
      // Trackers agregados 29/06/22: shorturl.at/efrJR
      case "hamburguer":
        this.$gaService.event("clic", "com_home", "menu_hamburguesa_movil");
        break;
      case "quiero_vender":
        this.$gaService.event("clic", "com_header", "quiero_vender");
        break;
      case "donde_comprar":
        this.$gaService.event("clic", "com_header", "donde_comprar");
        break;
      case "contacto":
        this.$gaService.event("clic", "com_header", "contacto");
        break;
      default:
        break;
    }
  }

  public isActive(path) {
    if (path == "/") {
      return this.router.isActive(path, true) ? true : false;
    } else {
      return this.router.isActive(path, false) ? true : false;
    }
  }
}
