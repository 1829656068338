import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEsAR from '@angular/common/locales/es-AR';
import localeEsARExtra from '@angular/common/locales/extra/es-AR';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { NgbDropdownModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { LaddaModule } from 'angular2-ladda';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { OwlModule } from 'ngx-owl-carousel';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { BusinessComponent } from './pages/business/business.component';
import { ProductsComponent } from './pages/catalog/products/products.component';
import { ShopsComponent } from './pages/catalog/shops/shops.component';
import { CuotsaleShopsComponent } from './pages/catalog/shopsCopy/shops.component';
import { ContactComponent } from './pages/contanct/contact.component';
import { FaqComponent } from './pages/faq/faq.component';
import { FrecuentQuestionsComponent } from './pages/frecuent-questions/frecuent-questions.component';
import { HomeComponent } from './pages/home/home.component';
import { HowItWorksComponent } from './pages/howItWorks/howItWorks.component';
import { LandingsComponent } from './pages/landings/landings.component';
import { MainComponent } from './pages/main/main.component';
import { PersonsComponent } from './pages/persons/persons.component';
import { WibondInfoComponent } from './pages/wibond-info/wibond-info.component';
import { AmountPipe } from './shared/amount.pipe';
import { AsideComponent } from './shared/components/aside/aside.component';
import { BusinessBenefitsSectionComponent } from './shared/components/business/business-benefits-section/business-benefits-section.component';
import { BusinessCreateSectionComponent } from './shared/components/business/business-create-section/business-create-section.component';
import { BusinessLastSectionComponent } from './shared/components/business/business-last-section/business-last-section.component';
import { BusinessOptionsSectionComponent } from './shared/components/business/business-options-section/business-options-section.component';
import { BusinessPluginSectionComponent } from './shared/components/business/business-plugin-section/business-plugin-section.component';
import { BuyWibond } from './shared/components/buy-wibond/buy-wibond.component';
import { CarouselHeaderComponent } from './shared/components/carousel-header/carousel-header.component';
import { DiscountCodeComponent } from './shared/components/discount-code/discount-code.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { CreateAccountComponent } from './shared/components/landings/create-account/create-account.component';
import { FaqLandingComponent } from './shared/components/landings/faq-landing/faq-landing.component';
import { MainLandingComponent } from './shared/components/landings/main-landing/main-landing.component';
import { OffersComponent } from './shared/components/landings/offers/offers.component';
import { OutstandingsComponent } from './shared/components/landings/outstandings/outstandings.component';
import { PaymentOptionsComponent } from './shared/components/landings/payment-options/payment-options.component';
import { ThirdSectiondLandingComponent } from './shared/components/landings/third-sectiond-landing/third-sectiond-landing.component';
import { LastSectionComponent } from './shared/components/last-section/last-section.component';
import { MultipleCarouselComponent } from './shared/components/multiple-carousel/multiple-carousel.component';
import { NavMenuComponent } from './shared/components/nav-menu/nav-menu.component';
import { PartnersHowToComponent } from './shared/components/partners-how-to/partners-how-to.component';
import { PartnershipSectionComponent } from './shared/components/partnership-section/partnership-section.component';
import { ProductsCatalogueComponent } from './shared/components/products-catalogue/products-catalogue.component';
import { ShopsCatalogueComponent } from './shared/components/shops/shops-catalogue.component';
import { StoreHowToComponent } from './shared/components/store-how-to copy/store-how-to.component';
import { ThirdSectionComponent } from './shared/components/third-section/third-section.component';
import { StorePromotionsComponent } from './shared/components/store-promotions/store-promotions.component';

const defaultLocale = 'es-AR'
registerLocaleData(localeEsAR, defaultLocale, localeEsARExtra)

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqComponent,
    AmountPipe,
    MainComponent,
    HowItWorksComponent,
    AsideComponent,
    FooterComponent,
    BusinessComponent,
    PersonsComponent,
    ProductsComponent,
    ShopsComponent,
    CuotsaleShopsComponent,
    ShopsCatalogueComponent,
    ProductCardComponent,
    NavMenuComponent,
    CarouselHeaderComponent,
    ThirdSectionComponent,
    MultipleCarouselComponent,
    ProductsCatalogueComponent,
    PartnershipSectionComponent,
    LastSectionComponent,
    FrecuentQuestionsComponent,
    ContactComponent,
    BusinessOptionsSectionComponent,
    BusinessLastSectionComponent,
    BusinessPluginSectionComponent,
    BusinessBenefitsSectionComponent,
    BusinessCreateSectionComponent,
    LandingsComponent,
    FaqLandingComponent,
    CreateAccountComponent,
    PaymentOptionsComponent,
    OffersComponent,
    OutstandingsComponent,
    ThirdSectiondLandingComponent,
    MainLandingComponent,
    BuyWibond,
    WibondInfoComponent,
    DiscountCodeComponent,
    PartnersHowToComponent,
    StoreHowToComponent,
    StorePromotionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AngularFullpageModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    NgxGoogleAnalyticsRouterModule,
    IvyCarouselModule,
    OwlModule,
    NgbDropdownModule,
    NgbModule,
    CarouselModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule,
    LaddaModule.forRoot({
      style: "contract",
      spinnerSize: 40,
      spinnerColor: "white",
      spinnerLines: 12
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
