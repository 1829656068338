import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LocationService } from 'src/app/services/location.service';
import { SendemailService } from 'src/app/services/sendemail.service';

@Component({
  selector: 'app-persons',
  templateUrl: './persons.component.html',
  styleUrls: ['./persons.component.scss']
})

export class PersonsComponent implements OnInit {
  config: any;
  fullpage_api: any;
  images;
  imagesMob;
  country;

  constructor(private renderer: Renderer2,
    private send: SendemailService, @Inject(DOCUMENT) private document: any,
    private $gaService: GoogleAnalyticsService, private locationService: LocationService) {
    this.country = this.locationService.getCountry();

    this.images = ["/assets/images/people/img-desk/headerpersonas.jpg", "/assets/images/people/img-desk/headerpersonas2.jpg", "/assets/images/people/img-desk/headerpersonas3.jpg"]
    this.imagesMob = ["/assets/images/people/img-mob/headerpersonas.jpg", "/assets/images/people/img-mob/headerpersonas2.jpg", "/assets/images/people/img-mob/headerpersonas3.jpg"]
  }

  ngOnInit() {
    this.$gaService.pageView('/personas', 'Personas')
  }

  clickBtn(action: string) {
    switch (action) {
      case 'signup':
        this.$gaService.event('signup', 'user_signup', 'Crear Cuenta');
        window.open("https://app.wibond.co/auth/signup-v3", "_blank");
        break;
      case 'login':
        this.$gaService.event('login', 'user_login', 'Login');
        window.open("https://app.wibond.co/auth/login", "_blank");
        break;
      case 'comenzar-ahora':
        this.$gaService.event('clic', 'com_home', 'crear_cuenta_body_home');
        window.open("https://app.wibond.co/auth/signup-v3?sourceCode=SitioWeb_CTACrearCuenta", "_blank");
        break;
      case 'integracion':
        this.$gaService.event('apidocs', 'apidocs', 'API doc');
        window.open("https://stoplight.io/p/docs/gh/itwibond/api-doc", "_blank");
        break;
      case 'tel':
        this.$gaService.event('telefono', 'telefono', 'Telefono click');
        window.open("https://api.whatsapp.com/send?phone=5491160283777&text=Hola,%20necesito%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Wibond.", "_blank");
        break;
      case 'email':
        this.$gaService.event('email', 'email', 'email hola');
        window.open("mailto:hola@wibond.co", "_blank");
        break;
      case 'faq':
        this.$gaService.event('faq', 'click', 'faqs');
        window.open("https://wibond.zendesk.com/hc/es", "_blank");
        break;
      default:
        break;
    }
  }

  public openwindow(url) {
    window.open(url, '_blank').focus();
  }
}