
import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LocationService } from 'src/app/services/location.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']

})

export class FooterComponent implements OnInit {
  @Input() fullFooter: boolean = true;
  @Input() landingNav: boolean = false;

  public country = { code: 10, value: 'AR', src: '/assets/images/people/svg/argentina.svg' }
  public countrys = [
    { code: 10, value: 'AR', name: 'Argentina', src: '/assets/images/people/svg/argentina.svg' },
    { code: 11, value: 'CL', name: 'Chile', src: '/assets/images/people/svg/Chile.svg' }
  ]
  public countryShow;

  constructor(@Inject(DOCUMENT) private document: any, private $gaService: GoogleAnalyticsService, private locationService: LocationService) {
  }

  ngOnInit() {
    this.country = this.countrys.find(country => country.value === this.locationService.getCountry() ? this.locationService.getCountry() : undefined);
    if(this.country == undefined){
      this.country = this.countrys[0]
    }
    this.countryShow = this.countrys.filter(element => element.code !== this.country.code);
  }

  public changeCountry(code) {
    this.country = this.countrys.find(country => country.code === code);
    this.countryShow = this.countrys.filter(element => element.code !== this.country.code)
    this.locationService.setCountry(this.country.value);
  }

  goToApp(action): void {
    let URL;
    if (action == 1) {
      URL = environment.URLlogin;
    }
    if (action == 2) {
      URL = environment.URLregistro;
    }
    this.document.location.href = URL;
  }

  clickBtn(action: string) {
    var params = "/?";
    if(this.country.value === 'CL') {
      params = params.concat("country=", 'CHILE')
    } else {
      params = params.concat("country=", 'ARGENTINA')
    }
    switch (action) {
      case 'signup':
        this.$gaService.event('clic', 'com_home', 'crear_cuenta_footer');
        window.open("https://app.wibond.co/auth/signup-v3" + params, "_blank");
        break;
      case 'login':
        this.$gaService.event('login', 'user_login', 'Login');
        window.open("https://app.wibond.co/auth/login" + params, "_blank");
        break;
      case 'comenzar-ahora':
        this.$gaService.event('comenzar-ahora', 'comenzar-ahora', 'Comenzar ahora');
        window.open("https://app.wibond.co/auth/signup-v3" + params, "_blank");
        break;
      case 'integracion':
        this.$gaService.event('apidocs', 'apidocs', 'API doc');
        window.open("https://stoplight.io/p/docs/gh/itwibond/api-doc", "_blank");
        break;
      case 'tel':
        this.$gaService.event('telefono', 'telefono', 'Telefono click');
        window.open("https://api.whatsapp.com/send?phone=5491160283777&text=Hola,%20necesito%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Wibond.", "_blank");
        break;
      case 'email':
        this.$gaService.event('email', 'email', 'email hola');
        window.open("mailto:hola@wibond.co", "_blank");
        break;
      case 'faq':
        this.$gaService.event('faq', 'click', 'faqs');
        window.open("https://wibond.zendesk.com/hc/es", "_blank");
        break;
      default:
        break;
    }
  }

  // Smooth scroll to top
  goToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  currentYear() {
    return new Date().getFullYear();
  }


}