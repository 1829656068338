import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SendemailService } from 'src/app/services/sendemail.service';
import { DOCUMENT } from "@angular/common";
import { timer } from 'rxjs';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { RouterModule } from '@angular/router';
import { LocationService } from 'src/app/services/location.service';


@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class BusinessComponent implements OnInit {
  config: any;
  fullpage_api: any;
  form: FormGroup;
  formOk: boolean = false;
  errorEnvio: boolean = false;
  loading: boolean;
  country;

  constructor(private renderer: Renderer2, private formBuilder: FormBuilder,
    private send: SendemailService, @Inject(DOCUMENT) private document: any,
    private $gaService: GoogleAnalyticsService,
    private router: RouterModule, private locationService: LocationService) {
    this.country = this.locationService.getCountry();
  }

  ngOnInit() {
    this.$gaService.pageView('/empresas', 'Empresas')
    this.form = this.formBuilder.group({
      nombre: ['', Validators.required],
      rol: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  clickBtn(action: string) {
    switch (action) {
      case 'signup':
        this.$gaService.event('home', 'signup', 'Crear Cuenta');
        window.open("https://app.wibond.co/auth/signup-v3", "_blank");
        break;
      case 'login':
        this.$gaService.event('home', 'login', 'Login');
        window.open("https://app.wibond.co/auth/login", "_blank");
        break;
      case 'comenzar-ahora':
        this.$gaService.event('home', 'comenzar_ahora', 'Comenzar ahora');
        window.open("https://app.wibond.co/auth/signup-v3", "_blank");
        break;
      case 'integracion':
        this.$gaService.event('home', 'apidocs', 'API doc');
        window.open("https://stoplight.io/p/docs/gh/itwibond/api-doc", "_blank");
      case 'tel':
        this.$gaService.event('home', 'telefono', 'Telefono click');
        window.open("https://api.whatsapp.com/send?phone=5491160283777&text=Hola,%20necesito%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Wibond.", "_blank");
      case 'email':
        this.$gaService.event('home', 'email', 'email hola');
        window.open("mailto:hola@wibond.co", "_blank");
      default:
        break;
    }
  }
  sendEmail() {
    this.$gaService.event('home', 'user_contact_form', 'Contactar');
    this.loading = true;
    this.send.sendEmail(this.form).subscribe(
      data => {
        this.formOk = true
        const source = timer(4000);
        const subscribe = source.subscribe(
          val => {
            this.formOk = false;
            this.loading = false;
          });
      },
      error => {
        this.errorEnvio = true;
        const source = timer(4000);
        const subscribe = source.subscribe(
          val => {
            this.errorEnvio = false;
            this.loading = false;
          });
      }
    );
  }
}