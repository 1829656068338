import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CatalogService } from 'src/app/pages/catalog/services/catalog.service';

@Component({
  selector: 'app-shops-catalogue',
  templateUrl: './shops-catalogue.component.html',
  styleUrls: ['./shops-catalogue.component.scss']
})
export class ShopsCatalogueComponent implements OnInit {
  public loading = true;
  public shops;
  public country = 'ARGENTINA'
  public page = 0;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    autoplayTimeout: 4000,
    navText: ["<img src='/assets/images/back.png'>","<img src='/assets/images/next.png'>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
  constructor(private _catalogService: CatalogService) { 
    
  }

  ngOnInit(): void {
    this._catalogService.getShopsCarousel().subscribe(shops =>{
      this.shops = shops.items;
      console.log(this.shops);
      this.loading = false;
    })
  }

}
