import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CatalogService extends ApiService {
  public url = environment.URLcatalogos;
  constructor(private http: HttpClient) {
    super()
  }

  getProducts(): Observable<any> {
    return this.http.get(`${this.url}/product?country=ARGENTINA`);
  }

  getProductsByShopOrTags(storeId?, tags?): Observable<any> {
    return this.http.get(this.url + '/product?' + ((storeId) ? (('storesId=' + storeId) + ((tags) ? ('&tags=' + tags) : '')) : 'tags=' + tags));
  }

  getLandingByBrand(brandName): Observable<any> {
    return this.http.get(`${this.url}/landingByBrand?brand=${brandName}`);
  }
  getProductsByCategory(country: string, pageIndex: number, pageSize: number, category: number, order: string, productName?: string, storeId?: number): Observable<any> {
    return this.http.get(`${this.url}/productsLanding?country=${country}&pageIndex=${pageIndex}&pageSize=${pageSize}&order=${order}&categoryId=${category ? category : ''}&name=${productName ? productName : ''}&catalogStoreId=${storeId ? storeId : ''}`);
  }

  getCategory(): Observable<any> {
    return this.http.get(`${this.url}/category`);
  }

  getSubCategory(): Observable<any> {
    return this.http.get(`${this.url}/subcategory`);
  }

  getCaption(): Observable<any> {
    return this.http.get(`${this.url}/caption`);
  }

  // Shops:

  getShops(country: string, pageIndex: number, pageSize: number): Observable<any> {
    return this.http.get(`${this.url}/storesLanding?country=${country}&pageIndex=${pageIndex}&pageSize=${pageSize}`)
  }
  getShopsByName(country: string, pageIndex: number, pageSize: number, shopName: string, categorySelected: any): Observable<any> {
    return (
      shopName ? this.http.get(`${this.url}/storesLanding?country=${country}&pageIndex=${pageIndex}&pageSize=${pageSize}&find=${shopName}&categoryId=${categorySelected}`) :
        this.http.get(`${this.url}/storesLanding?country=${country}&pageIndex=${pageIndex}&pageSize=${pageSize}&categoryId=${categorySelected}`))
  }

  getShopsCarousel(): Observable<any> {
    return this.http.get(`${this.url}/store?country=ARGENTINA`)

  }
}
