import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common'


@Pipe({
  name: 'amount'
})
export class AmountPipe implements PipeTransform {

  transform (value: any): string {
    // if (positive && value < 0) {
    //   value = -value
    // }
    return formatNumber(value, 'es-AR', '') || '0'
  }

}



