import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-business-create-section',
  templateUrl: './business-create-section.component.html',
  styleUrls: ['./business-create-section.component.scss']
})
export class BusinessCreateSectionComponent implements OnInit {

  country;

  constructor(private locationService: LocationService) {
    this.country = this.locationService.getCountry();
  }

  ngOnInit(): void {
  }

}
