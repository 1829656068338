import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SendemailService {
  public url = environment.URLcatalogos.replace("catalog","");;

  constructor(private http: HttpClient) { }

  // addHero (hero: Hero): Observable<Hero> {
  //   return this.http.post<Hero>(this.heroesUrl, hero, httpOptions)
  //     .pipe(
  //       catchError(this.handleError('addHero', hero))
  //     );
  // }

  sendEmail(body): Observable<any>{    
    return this.http.post(`${this.url}contact`, body);
  }
}
