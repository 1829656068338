import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { environment } from '../../../environments/environment';
import { Observable, interval, timer } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SendemailService } from 'src/app/services/sendemail.service';
import { error } from '@angular/compiler/src/util';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})



export class FaqComponent implements OnInit {
  scrollOffset: number = 0;
  form: FormGroup;
  formOk : boolean = false;
  loading : boolean = false;
  errorEnvio : boolean = false;

  

  constructor(@Inject(DOCUMENT) private document: any , private envio: SendemailService) { }

  ngOnInit() {

    this.form = new FormGroup({
      nombre: new FormControl('',[Validators.required]),
      email: new FormControl('',[Validators.email]),
      rol: new FormControl('',[Validators.required])
    });
   
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 550) {
       let element = document.getElementById('navbar');
       element.classList.add('sticky');
       let btn = this.document.getElementById('btn-cuenta');
       btn.classList.remove('oculto');
       btn.classList.add('visible');
       let logo = this.document.getElementById('logo');
       logo.setAttribute("src", "assets/images/logos/wb-logo-mdpi.png");
       this.document.getElementById('link1').classList.remove('white');
       this.document.getElementById('link1').classList.add('black');
       this.document.getElementById('link2').classList.remove('white');
       this.document.getElementById('link2').classList.add('black');
       
     } else {
      let element = document.getElementById('navbar');
        element.classList.remove('sticky'); 
      let btn = this.document.getElementById('btn-cuenta');
       btn.classList.add('oculto');
       btn.classList.remove('visible');
       let logo = this.document.getElementById('logo');
       logo.setAttribute("src", "assets/images/logos/isologo-blanco.png");
       this.document.getElementById('link1').classList.remove('black');
       this.document.getElementById('link1').classList.add('white');
       this.document.getElementById('link2').classList.remove('black');
       this.document.getElementById('link2').classList.add('white');
     }
  }
  onScroll(event){
    this.scrollOffset = event.srcElement.scrollTop;
  }
  goToApp(action): void {
    let URL;
    if(action==1){
      URL = environment.URLlogin;
    }
    if(action==2){
      URL = environment.URLregistro;
    }
    this.document.location.href = URL;
}
  goToFaq(): void {
    this.document.location.href = 'https://wibond.zendesk.com/hc/es';
  }

  sendEmail(): void {
    this.loading = true;
    this.envio.sendEmail(this.form).subscribe(
      data => {
        this.formOk = true;
        const source = timer(4000);
        const subscribe = source.subscribe(  
          val => {
            this.formOk = false;
            this.loading =false;
          } );
      }  , 
      error =>{
        this.errorEnvio = true;
        const source = timer(4000);
        const subscribe = source.subscribe(  
          val => {
            this.errorEnvio = false;
            this.loading =false;
          } );
      }   
    );   
  }

}
