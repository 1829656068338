import { Injectable } from '@angular/core';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export abstract class ParamsService {

  public params: any

  constructor() {

 


  }

  public getParams(key): any {
    return sessionStorage.getItem(key);
    
  }

  public setParams(key,value): any {
    sessionStorage.setItem(key, value);
  }



}
