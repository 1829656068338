import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-partners-how-to',
  templateUrl: './partners-how-to.component.html',
  styleUrls: ['./partners-how-to.component.scss']
})

export class PartnersHowToComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}