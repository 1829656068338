
import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';


@Component({
  selector: 'app-buy-wibond',
  templateUrl: './buy-wibond.component.html',
  styleUrls: ['./buy-wibond.component.scss']

})

export class BuyWibond implements OnInit {
  step: number = 1;
  country

  constructor(private locationService: LocationService) {
    this.country = this.locationService.getCountry();
  }
  ngOnInit() {

  }
  changeImage(step: number) {
    this.step = step;
  }
}