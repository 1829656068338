import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-carousel-header',
  templateUrl: './carousel-header.component.html',
  styleUrls: ['./carousel-header.component.scss']
})
export class CarouselHeaderComponent implements OnInit {

  country;

  constructor(private $gaService: GoogleAnalyticsService, private locationService: LocationService) {
    this.country = this.locationService.getCountry();
  }

  ngOnInit(): void {
  }

  clickBtn(action: string) {
    switch (action) {
      case 'signup':
        this.$gaService.event('signup', 'user_signup', 'Crear Cuenta');
        window.open("https://app.wibond.co/auth/signup-v3", "_blank");
        break;
      case 'login':
        this.$gaService.event('login', 'user_login', 'Login');
        window.open("https://app.wibond.co/auth/login", "_blank");
        break;
      case 'signup-ch':
        this.$gaService.event('clic', 'com_home', 'crear_cuenta_banner_homel');
        window.open("https://app.wibond.co/auth/signup-v3/?country=CHILE&sourceCode=SitioWeb_CTACrearCuenta", "_blank");
        break;
      case 'signup-ar':
        this.$gaService.event('clic', 'com_home', 'crear_cuenta_banner_homel');
        window.open("https://app.wibond.co/auth/signup-v3/?country=ARGENTINA&sourceCode=SitioWeb_CTACrearCuenta", "_blank");
        break;
      case 'integracion':
        this.$gaService.event('apidocs', 'apidocs', 'API doc');
        window.open("https://stoplight.io/p/docs/gh/itwibond/api-doc", "_blank");
        break;
      case 'tel':
        this.$gaService.event('telefono', 'telefono', 'Telefono click');
        window.open("https://api.whatsapp.com/send?phone=5491160283777&text=Hola,%20necesito%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Wibond.", "_blank");
        break;
      case 'shops':
        this.$gaService.event('clic', 'com_home', 'ver_tienda_banner');
        window.location.href = '/shops';
        break;
      case 'email':
        this.$gaService.event('email', 'email', 'email hola');
        window.open("mailto:hola@wibond.co", "_blank");
        break;
      case 'faq':
        this.$gaService.event('faq', 'click', 'faqs');
        window.open("https://wibond.zendesk.com/hc/es", "_blank");
        break;
      default:
        break;
    }
  }

}
