import { Component, OnInit, Input } from '@angular/core';
import { CatalogService } from 'src/app/pages/catalog/services/catalog.service';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() productData;
  @Input() categorys;
  public products;
  public loading = true;
  constructor(private catalogService: CatalogService) { }
  ngOnInit(): void {
  }


  public openwindow(url){
    window.open(url, '_blank').focus();
  }
}
