import { HttpParams } from '@angular/common/http'
import { environment } from '../../environments/environment';

const PAGE_LIMIT = 12

export abstract class ApiService {
  constructor () {
  }

  protected getCursorOptions (next?: string, params?: any, limit?: number): any {
    params = params || {}
    limit = limit || PAGE_LIMIT

    params.limit = limit.toString()
    if (next) {
      params.next = next
    }

    return { params }
  }

}
