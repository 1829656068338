import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit {
  @Input() brandName: string;
  @Input() offers: [];
  @Input() featuredProduct: any;

  public customOptions: OwlOptions;
  public loading = false;
  constructor() { }

  ngOnInit(): void {
    /* this.initializeCarousel(this.offers.length) */
  }

  getNumberPartOfString(str) {
    return str.match(/\d+/)[0];
  }

  public initializeCarousel(categorysLength) {
    this.customOptions = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      autoplay: true,
      autoplayTimeout: 4000,
      navText: ["<img src='/assets/images/back.png'>", "<img src='/assets/images/next.png'>"],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 4
        }
      },
      nav: true
    }
  }

  public openwindow(url) {
    window.open(url, '_blank').focus();
  }

  capitalizeFirstLetter() {
    return this.brandName.charAt(0).toUpperCase() + this.brandName.slice(1);
  }

}
