import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogService } from '../catalog/services/catalog.service';

@Component({
  selector: 'app-landings',
  templateUrl: './landings.component.html',
  styleUrls: ['./landings.component.scss']
})
export class LandingsComponent implements OnInit {
  public storeId = 1742911;
  public offers = [];
  public outstandings = [];
  public brandName: string;
  public landingInfo;
  public loading = true;
  constructor(private catalogService: CatalogService, private route: ActivatedRoute) {
    this.brandName = this.route.snapshot.params.shopName
  }

  ngOnInit(): void {
    this.catalogService.getLandingByBrand(this.brandName).subscribe(res => {
      if (res.length === 0) {
        window.location.href = "/"
        console.log("entra")
      }
      this.landingInfo = res[0];
      this.landingInfo.faqsdata = JSON.parse(this.landingInfo.faqsdata.replace("'\'", " "));
      console.log(this.landingInfo.faqsdata[0].respuesta)
      this.getProducts();
    })
  }

  public getProducts() {

    this.catalogService.getProductsByShopOrTags(this.landingInfo.catalogStores ? this.landingInfo.catalogStores : null, this.landingInfo.tags ? this.landingInfo.tags : null).subscribe(res => {
      res.items.filter(x => x.showInLanding == true).forEach(product => {
        if (product.outstanding) {
          this.offers.push(product)
        }
      })
      this.outstandings = res.items.filter(x => x.showInLanding == true);
      this.loading = false;
    })

  }



}
