import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-business-options-section',
  templateUrl: './business-options-section.component.html',
  styleUrls: ['./business-options-section.component.scss']
})
export class BusinessOptionsSectionComponent implements OnInit {

  country;

  constructor(private locationService: LocationService) {
    this.country = this.locationService.getCountry();

  }

  ngOnInit(): void {
  }

}
