import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { LocationService } from 'src/app/services/location.service';
import { SendemailService } from 'src/app/services/sendemail.service';

@Component({
  selector: 'app-last-section',
  templateUrl: './last-section.component.html',
  styleUrls: ['./last-section.component.scss']
})
export class LastSectionComponent implements OnInit {

  form: FormGroup;
  formOk: boolean = false;
  loading: boolean = false;
  errorEnvio: boolean = false;
  country;

  constructor(private formBuilder: FormBuilder,
    private $gaService: GoogleAnalyticsService,
    private send: SendemailService, private locationService: LocationService) {
    this.country = this.locationService.getCountry();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }
  sendEmail() {
    let originContact
    if(this.country === 'CL') {
      originContact = 'LANDING_NEWSLETTER_CL'
    } else if(this.country === 'AR') {
      originContact = 'LANDING_NEWSLETTER'
    }
    let form = {
      email: this.form.controls.email.value,
      originContact: originContact
    }
    this.loading = true;
    this.send.sendEmail(form).subscribe(
      data => {
        this.formOk = true
        this.loading = false;
        this.$gaService.event('home', 'quiero_informacion_body', 'Quiero mas info');
        setTimeout(() => {
          this.loading = false;
          this.errorEnvio = false;
          this.formOk = false;
          this.form.reset();
        }, 5000);
      },
      error => {
        this.errorEnvio = true;
        this.loading = false;
      }
    );
  }
  public retry() {
    this.loading = false;
    this.errorEnvio = false;
    this.formOk = false;
  }
}
