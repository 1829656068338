import { Component, OnInit } from '@angular/core';
import { timer } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-store-how-to',
  templateUrl: './store-how-to.component.html',
  styleUrls: ['./store-how-to.component.scss']
})

export class StoreHowToComponent implements OnInit {

  public selected: number = 1;
  public country = { code: 10, value: 'AR', name: 'ARGENTINA' }
  public countrys = [
    { code: 10, value: 'AR', name: 'ARGENTINA' },
    { code: 11, value: 'CL', name: 'CHILE' }
  ]

  constructor(
    private locationService: LocationService
  ) { }

  ngOnInit() {
    this.country = this.countrys.find(country => country.value === this.locationService.getCountry() ? this.locationService.getCountry() : undefined);
    const source = timer(4000, 4000).subscribe(x => {
      this.selected = (this.selected == 4) ? (this.selected = 1) : (this.selected = this.selected + 1);
    });
  }

}