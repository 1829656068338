import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { StringifyOptions } from 'querystring';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-multiple-carousel',
  templateUrl: './multiple-carousel.component.html',
  styleUrls: ['./multiple-carousel.component.scss']
})
export class MultipleCarouselComponent implements OnInit {
  public loading = false;
  @Input() customOptions: OwlOptions
  @Input() items: [];
  @Input() type;

  constructor( private $gaService: GoogleAnalyticsService,) { }

  ngOnInit(): void {
  }
  public openwindow(url){
    window.open(url, '_blank').focus();
  }

  clickBtn (action:string) {
    switch(action) {
      case 'donde_comprar':
        this.$gaService.event('clic', 'com_home', 'donde_comprar_body_home')
        break;
      default:
        break;
    }
  }
}
