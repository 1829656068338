import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-third-sectiond-landing',
  templateUrl: './third-sectiond-landing.component.html',
  styleUrls: ['./third-sectiond-landing.component.scss']
})
export class ThirdSectiondLandingComponent implements OnInit {
  @Input() landingInfo;
  constructor() { }

  ngOnInit(): void {
  }
  
  goTo(){
    window.open(this.landingInfo.urlFeaturedProduct, '_blank').focus();
  }
}
