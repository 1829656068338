import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CatalogService } from 'src/app/pages/catalog/services/catalog.service';

@Component({
  selector: 'app-products-catalogue',
  templateUrl: './products-catalogue.component.html',
  styleUrls: ['./products-catalogue.component.scss']
})
export class ProductsCatalogueComponent implements OnInit {
  categorys;
  products: [];
  allProducts;
  loading = true;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: false,
    autoplayTimeout: 4000,
    navText: ["<img src='/assets/images/back.png'>", "<img src='/assets/images/next.png'>"],
    responsive: {
      0: {
        items: 1
      },
      700: {
        items: 2
      },
      950: {
        items: 3
      },
      1268: {
        items: 4
      }
    },
    nav: true
  }
  constructor(private catalogService: CatalogService) { }

  ngOnInit(): void {
    this.catalogService.getCategory().subscribe(res => {
      this.categorys = res;
      this.getProductsByCategory();
    })
  }
  public getProductsByCategory() {
    this.catalogService.getProducts().subscribe(res => {
      this.products = res.items
      this.loading = false;
    });
  }
}