import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {

  constructor( private $gaService: GoogleAnalyticsService,) { }

  ngOnInit(): void {
  }

  clickBtn(action: string) {
    switch (action) {
     case 'signup':
       this.$gaService.event('signup', 'user_signup', 'Crear Cuenta');
       window.open("https://app.wibond.co/auth/signup-v3", "_blank");
       break;
     case 'login':
       this.$gaService.event('login', 'user_login', 'Login');
       window.open("https://app.wibond.co/auth/login", "_blank");
       break;
     case 'crear_cuenta_body_home':
       this.$gaService.event('clic', 'com_home', 'crear_cuenta_body_home');
       window.open("https://app.wibond.co/auth/signup-v3", "_blank");
       console.log('done: '+action);
       
       break;
     case 'integracion':
       this.$gaService.event('apidocs', 'apidocs', 'API doc');
       window.open("https://stoplight.io/p/docs/gh/itwibond/api-doc", "_blank");
     case 'tel':
       this.$gaService.event('telefono', 'telefono', 'Telefono click');
       window.open("https://api.whatsapp.com/send?phone=5491160283777&text=Hola,%20necesito%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Wibond.", "_blank");
     case 'email':
       this.$gaService.event('email', 'email', 'email hola');
       window.open("mailto:hola@wibond.co", "_blank");
     default:
       break;
   }
 }

}
