import { Component, OnInit } from '@angular/core';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-business-benefits-section',
  templateUrl: './business-benefits-section.component.html',
  styleUrls: ['./business-benefits-section.component.scss']
})
export class BusinessBenefitsSectionComponent implements OnInit {
  country;

  constructor(private locationService: LocationService) {
    this.country = this.locationService.getCountry();
  }

  ngOnInit(): void {
  }

}
