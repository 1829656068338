import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  CountryISO,
  SearchCountryField
} from "ngx-intl-tel-input";
import { LocationService } from 'src/app/services/location.service';
import { SendemailService } from 'src/app/services/sendemail.service';
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit {
  @Input() landing: boolean = false;
  @Input() brandName = '';
  form: FormGroup;
  formOk: boolean = false;
  loading: boolean = false;
  errorEnvio: boolean = false;
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  country;

  constructor(private formBuilder: FormBuilder,
    private send: SendemailService, private locationService: LocationService) {
    this.country = this.locationService.getCountry();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      comments: ['', Validators.required],
    });
  }

  sendEmail() {
    let origin
    if(!this.landing) {
      if(this.country === 'CL') {
        origin = 'LANDING_CONTACT_CL'
      } else if(this.country === 'AR') {
        origin = 'LANDING_CONTACT'
      }
    }
    this.loading = true;
    let form = {
      name: this.form.controls.fullName.value,
      email: this.form.controls.email.value,
      subject: this.form.controls.subject.value,
      phone: this.form.controls.phoneNumber.value.nationalNumber,
      comment: this.form.controls.comments.value,
      originContact: !this.landing ? origin : 'LANDING_' + this.brandName.toLocaleUpperCase()
    }
    this.send.sendEmail(form).subscribe(
      res => {
        this.loading = false;
        this.formOk = true
        setTimeout(() => {
          this.loading = false;
          this.errorEnvio = false;
          this.formOk = false;
          this.form.reset();
        }, 5000);

      },
      error => {
        this.loading = false;
        this.errorEnvio = true;
      }
    );
  }

  public retry() {
    this.loading = false;
    this.errorEnvio = false;
    this.formOk = false;
    this.form.reset();
  }
}
