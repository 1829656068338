import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { environment } from '../../../environments/environment';
import { Observable, interval, timer } from 'rxjs';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SendemailService } from 'src/app/services/sendemail.service';
import { error } from '@angular/compiler/src/util';
import * as $ from 'jquery';

@Component({
  selector: 'app-howItWorks',
  templateUrl: './howItWorks.component.html',
  styleUrls: ['./howItWorks.component.scss']
})



export class HowItWorksComponent implements OnInit {
  builder: any;
  form: FormGroup;
  formOk: boolean = false;
  loading: boolean = false;
  errorEnvio : boolean = false;
  errorEnvioFiels :boolean = false;


  constructor(@Inject(DOCUMENT) private document: any, private send: SendemailService, private formBuilder: FormBuilder) { }


  ngOnInit() {
    this.form = this.formBuilder.group({
      nombre: ['', Validators.required],
      rol: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
    $('#up1, #up2, #up3, #up4, #up5, #up5, #up6').on('click', function (e) {
      e.preventDefault();
      switch ($(this).get(0).id) {
        case 'up1':
          move('#easy');
          break;
        case 'up2':
          move('#payments');
          break;
        case 'up3':
          move('#faq');
          break;
        case 'up4':
          move('#features');
          break;
        case 'up5':
          move('#contact');
          break;

        default:
          break;
      }

      function move(id) {
        var target = $(id);
        $('html, body').stop().animate({
          scrollTop: target.offset().top
        }, 1000);
      }


    });

    $(window).scroll(function(){
      let threshold: number = 800;
      let timeAnimation: number = 500;
      if($(window).scrollTop() > threshold){
        $("#btn-cta").fadeOut(timeAnimation);
      } else {
        $("#btn-cta").fadeIn(timeAnimation);
      }
    });

    var myIndex = 0;
    carousel();

    function carousel() {
      var i;
      var x = document.getElementsByClassName("mySlides");
      for (i = 0; i < x.length; i++) {
        x[i].setAttribute("style", "display:none;");
      }
      myIndex++;
      if (myIndex > x.length) { myIndex = 1 }
      x[myIndex - 1].setAttribute("style", "display:block;");//setAttribute("visibility","visible");// style.display = "block";  
      setTimeout(carousel, 2000); // Change image every 2 seconds
    }



    function moveToSelected(element) {

      if (element == "next") {
        var selected = $(".selected").next();
      } else if (element == "prev") {
        var selected = $(".selected").prev();
      } else {
        var selected = element;
      }

      var next = $(selected).next();
      var prev = $(selected).prev();
      var prevSecond = $(prev).prev();
      var nextSecond = $(next).next();

      $(selected).removeClass().addClass("selected");

      $(prev).removeClass().addClass("prev");
      $(next).removeClass().addClass("next");

      $(nextSecond).removeClass().addClass("nextRightSecond");
      $(prevSecond).removeClass().addClass("prevLeftSecond");

      $(nextSecond).nextAll().removeClass().addClass('hideRight');
      $(prevSecond).prevAll().removeClass().addClass('hideLeft');

    }

    // Eventos teclado
    $(document).keydown(function (e) {
      switch (e.which) {
        case 37: // left
          moveToSelected('prev');
          break;

        case 39: // right
          moveToSelected('next');
          break;

        default: return;
      }
      e.preventDefault();
    });

    $('#carousel div').click(function () {
      moveToSelected($(this));
    });

    $('#prev').click(function () {
      moveToSelected('prev');
    });

    $('#next').click(function () {
      moveToSelected('next');
    });

  }



  goToApp(action): void {

    let URL;

    if (action == 1) {
      URL = environment.URLlogin;
    }
    if (action == 2) {
      URL = environment.URLregistro;
    }
    if (action == 3) {
      URL = 'https://wibond.zendesk.com/hc/es'
    }

    this.document.location.href = URL;


  }

  sendEmail() {
    this.loading = true;
    if (this.form.invalid) {
      alert("invalid");
      this.loading = false;
       this.errorEnvioFiels = true;
        const source = timer(4000);
          const subscribe = source.subscribe(
            val => {
              this.errorEnvioFiels = false;
              this.loading = false;
            });
    }
    else{
      this.send.sendEmail(this.form).subscribe(
        data => {
          this.formOk = true;
          const source = timer(4000);
          const subscribe = source.subscribe(
            val => {
              this.formOk = false;
              this.loading = false;
              this.form.reset();
            });
        },
        error => {
          this.errorEnvio = true;
          const source = timer(4000);
          const subscribe = source.subscribe(
            val => {
              this.errorEnvio = false;
              this.loading = false;
            });
        }
      );
    }
  }


}




