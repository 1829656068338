import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { environment } from '../../../environments/environment';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import * as $ from 'jquery';
import { stringify } from 'querystring';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  scrollOffset: number = 0;
  public ahorroA = 0;
  public ahorroB = 0;
  mainForm: FormGroup;
  public strA;

  constructor(@Inject(DOCUMENT) private document: any) {
    // this.mainForm = this.getForm();
  }

  // getForm(): FormGroup {
  //   return new FormGroup({
  //     dato: new FormControl()
  //   })
  // }

  ngOnInit() {
    this.mainForm = new FormGroup({
      dato: new FormControl()
   });

    $('#up1, #up2, #up3, #up4, #up5, #up5, #up6').on('click', function (e) {
      e.preventDefault();
      switch ($(this).get(0).id) {
        case 'up1':
          move('#advantages');
          break;
        case 'up2':
          move('#simulator');
          break;
        case 'up3':
          move('#information-down');
          break;
        case 'up4':
          move('#features');
          break;
        case 'up5':
          move('#contact');
          break;

        default:
          break;
      }

      function move(id) {
        var target = $(id);
        $('html, body').stop().animate({
          scrollTop: target.offset().top
        }, 1000);
      }


    });

    $(window).scroll(function(){
      let threshold: number = 800;
      let timeAnimation: number = 500;
      if($(window).scrollTop() > threshold){
        //$("#btn-cta-left").animate({opacity: 1}, timeAnimation);
        $("#btn-cta").fadeOut(timeAnimation, function(){ $("#crear-cuenta-left").css("display", "block");});
        //$("#crear-cuenta-left").css('opacity', '1');
      } else {
        //$("#btn-cta-left").animate({opacity: 0}, timeAnimation);
        $("#btn-cta").fadeIn(timeAnimation, function(){ $("#crear-cuenta-left").css("display", "none"); });
      }
    });
    
  }

  goToApp(action): void {
    let URL;
    if (action == 1) {
      URL = environment.URLlogin;
    }
    if (action == 2) {
      URL = environment.URLregistro;
    }
    this.document.location.href = URL;
  }
  goToRegister(): void {
    const URLlogin = environment.URLlogin;
    this.document.location.href = URLlogin;
  }
  goToFaq(): void {
    this.document.location.href = '/faq';
  }

  calculoAhorro() {
    const aux = this.mainForm.get('dato').value;
    let a;
    let b;
    a = aux * 0.031;
    b = aux * 0.073;
    this.ahorroA = a.toFixed();
    this.ahorroB = b.toFixed();
  }
  submitForm() {

  }

  newAmount(event: any) { // without type info

    let allowedKeys: string[] = ["0","1","2","3","4","5","6","7","8","9","Backspace","Delete","ArrowLeft","ArrowRight","."];
    if(allowedKeys.includes(event.key)){
      this.calculoAhorro();
    } else {
      event.preventDefault();
    }

  }



}
