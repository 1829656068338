import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
import { environment } from '../../../environments/environment';
import { FormGroup, FormControl, FormArray } from '@angular/forms';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  scrollOffset: number = 0;
  public ahorroA =0;
  public ahorroB =0;
  mainForm: FormGroup;
  public strA;

  constructor(@Inject(DOCUMENT) private document: any ) { 
    this.mainForm = this.getForm();
  }

  getForm(): FormGroup {
    return new FormGroup({
      dato: new FormControl()
    })
  }

  ngOnInit() {
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.pageYOffset > 550) {
       let element = document.getElementById('navbar');
       element.classList.add('sticky');
       let btn = this.document.getElementById('btn-cuenta');
       btn.classList.remove('oculto');
       btn.classList.add('visible');
       
     } else {
      let element = document.getElementById('navbar');
        element.classList.remove('sticky'); 
      let btn = this.document.getElementById('btn-cuenta');
       btn.classList.add('oculto');
       btn.classList.remove('visible');
     }
  }
  onScroll(event){
    this.scrollOffset = event.srcElement.scrollTop;
  }
  goToApp(action): void {
    let URL;
    if(action==1){
      URL = environment.URLlogin;
    }
    if(action==2){
      URL = environment.URLregistro;
    }
    this.document.location.href = URL;
}
  goToRegister(): void {
    const URLlogin = environment.URLlogin;
    this.document.location.href = URLlogin;
  }
  goToFaq(): void {
    this.document.location.href = '/faq';
  }

  calculoAhorro(){
    const aux = this.mainForm.get('dato').value;
    let a; 
    let b;
    a = aux * 0.031;
    b = aux * 0.073;
    this.ahorroA = a.toFixed();
    this.ahorroB = b.toFixed();
  }
  submitForm() {

  }

}
