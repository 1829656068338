import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { LocationService } from 'src/app/services/location.service';
import { CatalogService } from '../services/catalog.service';


@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss']
})
export class CuotsaleShopsComponent implements OnInit {
  public shopsList;
  public cuotsaleShopsList;
  public loading = true;
  public deals;
  public outstanding;
  public page = 0;
  public pageSize = 12;
  public collectionSize;
  public countrys = [
    { code: 10, value: 'AR', name: 'ARGENTINA'},
    { code: 11, value: 'CL', name: 'CHILE'}
  ];
  public country = { code: 10, value: 'AR', name: 'ARGENTINA'}
  public categorys;
  public categorySelected = 'Todos';
  public totalPages;
  public customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    autoplay: true,
    autoplayTimeout: 4000,
    responsive: {
      0: {
        items: 1
      },
      860: {
        items: 2
      },
      1200: {
        items: 3
      },
      1550: {
        items: 4
      }
    },
    nav: false
  }
  public changeCategory = true;
  form: FormGroup;
  formOk: boolean = false;
  public landing;
  public placeholder = window.innerWidth < 1000 ? "Busca lo que quieras. Ej: Zapatillas" : "Buscá tu tienda favorita o lo que necesitas. Ej: Zapatillas";;
  constructor(
    private catalogService: CatalogService,
    private locationService: LocationService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,) {

      
    this.form = this.formBuilder.group({
      shopName: ['', Validators.required],
    })
  }



  ngOnInit() {
    this.cuotsaleShopsList = [
      {
        id: 1,
        title: '47 Street',
        image: '/assets/images/cuotSale/tiendas/47-street.png',
        url: 'https://www.47street.com.ar/'
      },
      {
        id: 2,
        title: 'Armá tu hogar',
        image: '/assets/images/cuotSale/tiendas/arma-tu-hogar.png',
        url: 'https://www.armatuhogar.com.ar/'
      },
      {
        id: 3,
        title: 'Farmamix',
        image: '/assets/images/cuotSale/tiendas/farmamix.png',
        url: 'https://www.farmamix.com.ar/'
      },
      {
        id: 4,
        title: 'Handicap',
        image: '/assets/images/cuotSale/tiendas/handicap.png',
        url: 'https://www.handicapdeportes.com.ar/'
      },
      {
        id: 5,
        title: 'Megatone',
        image: '/assets/images/cuotSale/tiendas/megatone.png',
        url: 'https://www.megatone.net/'
      },
      {
        id: 6,
        title: 'PODIUM',
        image: '/assets/images/cuotSale/tiendas/podium.png',
        url: 'https://tiendapodium.com.ar/'
      },
      {
        id: 7,
        title: 'Yuhmak',
        image: '/assets/images/cuotSale/tiendas/yuhmak.png',
        url: 'https://www.yuhmak.com/'
      }
    ]

    this.route.queryParams.subscribe(params => {
        if(params && params.country && params.country == 'CHILE'){
          if(this.locationService.getCountry() !== 'CL'){
            this.locationService.setCountry('CL')
          }
        }
    });

    this.country = this.countrys.find(c => c.value === this.locationService.getCountry());
    console.warn(this.country);
    this.catalogService.getShops(this.country.name, this.page, this.pageSize).subscribe(res => {
      this.shopsList = res.content;
      this.collectionSize = res.totalElements;
      this.totalPages = res.totalPages
      this.loading = false;
    })


    this.catalogService.getCategory().subscribe(res => {
      this.categorys = res;
      this.initializeCarousel(res.length)
      this.categorys = this.categorys.map(cat => ({
        ...cat,
        img: '/assets/images/Productos/svg/' + this.removeAccents(cat.name.toLowerCase()) + '.svg',
        imgActive: '/assets/images/Productos/svg/' + this.removeAccents(cat.name.toLowerCase()) + '_active.svg',
        active: false
      }));
    })
  }

  public removeAccents = (str) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }


  public initializeCarousel(categorysLength) {
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      autoplay: false,
      autoplayTimeout: 4000,
      navText: ["<img src='/assets/images/back.png'>", "<img src='/assets/images/next.png'>"],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: categorysLength < 2 ? categorysLength : 2
        },
        740: {
          items: categorysLength < 3 ? categorysLength : 3
        },
        940: {
          items: categorysLength < 8 ? categorysLength : 8
        }
      },
      nav: true
    }
  }

  public nextPage(event) {
    this.loading = true;
    if (!this.changeCategory) {
      this.catalogService.getShopsByName(this.country.name, event - 1, this.pageSize, this.removeAccents(this.form.controls.shopName.value), this.categorySelected == 'Todos' ? '' : this.categorySelected).subscribe(res => {
        this.loading = false;
        this.shopsList = res.content;
        this.collectionSize = res.totalElements
        /* document.getElementById("iShopsContainer").scrollIntoView({ behavior: 'smooth' }); */
      })
    } else {
      this.changeCategory = false;
      this.loading = false;
    }
  }

  public findShop() {
    this.changeCategory = true;
    this.page = 0;
    this.pageSize = 12;
    this.loading = true;
    this.catalogService.getShopsByName(this.country.name, this.page, this.pageSize, this.removeAccents(this.form.controls.shopName.value), this.categorySelected == 'Todos' ? '' : this.categorySelected).subscribe(res => {
      this.loading = false;
      this.shopsList = res.content;
      this.collectionSize = res.totalElements;
      this.totalPages = res.totalPages;
      this.categorySelected = (this.categorySelected == '') ? ('Todos') : this.categorySelected;
      /* document.getElementById("iShopsContainer").scrollIntoView({ behavior: 'smooth' }); */
    })
  }

  public openwindow(url) {
    window.open(url, '_blank').focus();
  }

  public choseCategory(categoryId) {
    if (categoryId == 'Todos') {
      categoryId = this.categorySelected
    } else {
      categoryId = Number(categoryId);
    }
    this.changeCategory = true;
    let catActive = this.categorys.find(category => category.id === categoryId)
    if (catActive.active) {
      this.categorySelected = '';
      this.categorys = this.categorys.map((category => ({
        ...category,
        active: false
      })))
    } else {
      this.categorys = this.categorys.map((category => ({
        ...category,
        active: false
      })
      ))
      this.categorys.forEach((element => {
        if (element.id === categoryId) {
          element.active = true;
          this.categorySelected = element.id;
        }
      }))
    }
    this.findShop();
  }

  public scrollTo(id) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.placeholder = event.target.innerWidth < 1000 ? "Busca lo que quieras. Ej: Zapatillas" : "Buscá tu tienda favorita o lo que necesitas. Ej: Zapatillas";

  }
}
