import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export abstract class LocationService {

  private countrys = [
    { code: 10, value: 'AR', name: 'Argentina', src: '/assets/images/people/svg/argentina.svg' },
    { code: 11, value: 'CL', name: 'Chile', src: '/assets/images/people/svg/Chile.svg' }
  ]

  private country = this.getInitialCountry();

  getInitialCountry() {
    if (localStorage.getItem("country")) {
      return localStorage.getItem("country")
    } else {
      if (window.location.href.toUpperCase().includes(".CL")) {
        return 'CL'
      }
      let lang = navigator.language.split('-')[1];
      if (this.countrys.find(country => country.value === lang)) {
        return lang;
      } else {
        return 'AR';
      }
    }
      // if (window.location.href.toUpperCase().includes(".CO") || window.location.href.toUpperCase().includes(".AR") || window.location.href.toUpperCase().includes("LOCALHOST")) {
      //   return 'AR'
      // } else if (window.location.href.toUpperCase().includes(".CL")) {
      //   return 'CL'
      // }
    // return 'AR'
  }

  constructor(private route: ActivatedRoute, private router: Router) {
    // if (!localStorage.getItem("country")) {
    //   if (window.location.href.toUpperCase().includes(".CO") || window.location.href.toUpperCase().includes(".AR") || window.location.href.toUpperCase().includes("LOCALHOST")) {
    //     this.country = 'AR';
    //     localStorage.setItem("country", "AR");
    //   } else if (window.location.href.toUpperCase().includes(".CL")) {
    //     this.country = 'CL';
    //     localStorage.setItem("country", "CL");
    //   }
    // }
    localStorage.setItem("country", this.country);
  }

  public getCountry(): any {
    return this.country;
  }

  public getCountries(): any {
    return this.countrys;
  }

  public setCountry(country: any) {
    if (country) {
      this.country = country;
      localStorage.setItem("country", country);
      window.location.replace(location.pathname)
    }

  }

}
