import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ParamsService } from 'src/app/services/params.service';
import { CatalogService } from '../services/catalog.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  public categorys;
  public productsList;
  public page = 0;
  public pageSize = 12;
  public collectionSize;
  public country = 'ARGENTINA'
  public categorySelected;
  public loading = true;
  public totalPages;
  public shops;
  public order = { code: 'name', name: 'Nombre' };
  public store = { id: null, name: 'Nombre' };

  public allShop = [{ id: null, name: 'Todos' }];
  public customOptions: OwlOptions;
  public changeCategory = true;
  public orderByOptions = [
    { code: 'name', name: 'Nombre' },
    { code: 'amount', name: 'Precio' },
    { code: 'discountRate', name: 'Descuento' },

  ]
  form: FormGroup;
  formOk: boolean = false;

  public actions: any
  public utm_source: string
  public utm_medium: string
  public utm_campaign: string
  public gclid: string
  params = "/?";
  public landing;

  constructor(private catalogService: CatalogService, private formBuilder: FormBuilder,
    private paramsService: ParamsService) {
    this.form = this.formBuilder.group({
      shopName: ['', Validators.required],
    })

    this.catalogService.getShopsCarousel().subscribe(res => {
      this.shops = this.allShop.concat(res.items);
    })

    this.catalogService.getProductsByCategory(this.country, this.page, this.pageSize, this.categorySelected, this.order.code).subscribe(res => {
      this.loading = false;
      this.productsList = res.content;
      this.totalPages = res.totalPages;
      this.collectionSize = res.totalElements;
    })


  }

  ngOnInit(): void {

    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    this.catalogService.getCategory().subscribe(res => {
      this.categorys = res;
      this.initializeCarousel(res.length)
      this.categorys = this.categorys.map(cat => ({
        ...cat,
        img: '/assets/images/Productos/svg/' + removeAccents(cat.name.toLowerCase()) + '.svg',
        imgActive: '/assets/images/Productos/svg/' + removeAccents(cat.name.toLowerCase()) + '_active.svg',
        active: false
      }));
    })

    this.actions = this.paramsService.params


    if (this.paramsService.getParams("utm_source") != undefined) { this.params = this.params.concat('utm_source=', this.paramsService.getParams("utm_source"), '&') }
    if (this.paramsService.getParams("utm_medium") != undefined) { this.params = this.params.concat("utm_medium=", this.paramsService.getParams("utm_medium"), "&") }
    if (this.paramsService.getParams("utm_campaign") != undefined) { this.params = this.params.concat("utm_campaign=", this.paramsService.getParams("utm_campaign"), "&") }
    if (this.paramsService.getParams("gclid") != undefined) { this.params = this.params.concat("gclid=", this.paramsService.getParams("gclid")) }

  }

  public initializeCarousel(categorysLength) {
    this.customOptions = {
      loop: false,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      navSpeed: 700,
      autoplay: false,
      autoplayTimeout: 4000,
      navText: ["<img src='/assets/images/back.png'>", "<img src='/assets/images/next.png'>"],
      responsive: {
        0: {
          items: 2
        },
        400: {
          items: categorysLength < 2 ? categorysLength : 2
        },
        740: {
          items: categorysLength < 3 ? categorysLength : 3
        },
        940: {
          items: categorysLength < 8 ? categorysLength : 8
        }
      },
      nav: true
    }
    this.categorySelected = false;
  }

  public openwindow(url) {

    window.open(url + this.params, '_blank').focus();
  }

  public choseCategory(categoryId) {
    this.changeCategory = true;
    let catActive = this.categorys.find(category => category.id === categoryId)
    if (catActive.active) {
      this.categorySelected = '';
      this.categorys = this.categorys.map((category => ({
        ...category,
        active: false
      })))
    } else {
      this.categorys = this.categorys.map((category => ({
        ...category,
        active: false
      })
      ))
      this.categorys.forEach((element => {
        if (element.id === categoryId) {
          element.active = true;
          this.categorySelected = element.id;
        }
      }))
    }
    this.findProduct();
  }

  public findProduct() {
    this.page = 0;
    this.pageSize = 12;
    this.loading = true;
    this.catalogService.getProductsByCategory(this.country, this.page, this.pageSize, this.categorySelected, this.order.code, this.form.controls.shopName.value, this.store?.id).subscribe(res => {
      this.loading = false;
      this.productsList = res.content;
      this.collectionSize = res.totalElements;
      this.totalPages = res.totalPages;
      document.getElementById("iShopsContainer").scrollIntoView({ behavior: 'smooth' });
    })
  }

  public changeOrderBy(order) {
    this.order = this.orderByOptions.find(o => o.code === order)
    this.findProduct();
  }

  public filterByStore(storeId) {
    this.store = this.shops.find(o => o.id === storeId)
    this.findShop();
  }

  public nextPage(event) {
    this.loading = true;
    if (!this.changeCategory) {
      this.catalogService.getProductsByCategory(this.country, event - 1, this.pageSize, this.categorySelected, this.order.code).subscribe(res => {
        this.loading = false;
        this.productsList = res.content;
        this.collectionSize = res.totalElements
        document.getElementById("iShopsContainer").scrollIntoView({ behavior: 'smooth' });
      })
    } else {
      this.loading = false;
      this.changeCategory = false;
    }
  }

  public findShop() {
    this.changeCategory = true;
    this.page = 0;
    this.pageSize = 12;
    this.loading = true;
    this.catalogService.getProductsByCategory(this.country, this.page, this.pageSize, this.categorySelected, this.order.code, this.form.controls.shopName.value, this.store?.id).subscribe(res => {
      this.loading = false;
      this.productsList = res.content;
      this.collectionSize = res.totalElements;
      this.totalPages = res.totalPages;
      document.getElementById("iShopsContainer").scrollIntoView({ behavior: 'smooth' });
    })
  }

}

